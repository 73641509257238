@import "https://cdn.jsdelivr.net/npm/@commercelayer/drop-in.js@1.0.1/dist/drop-in/drop-in.css";
@import "https://cdn.jsdelivr.net/npm/@commercelayer/drop-in.js@1.0.1/dist/drop-in/minicart.css";

cl-price-amount {
  color: unset;
  font-size: unset;
  font-weight: unset;
  line-height: unset;
}

cl-cart-count {
  border: 2px solid white;
}

cl-add-to-cart {
  font-weight: unset;
  color: unset;
  padding: unset;
  border-radius: unset;
  background-color: unset;
}
